import React from "react";
import Record from "./Record";
import Button from "react-bootstrap/Button";

class MicToggle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            audio: null,
            sending: false,
            alreadyPlayed: false
        };
        this.toggleMicrophone = this.toggleMicrophone.bind(this);
    }

    getMicrophone() {
        navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false
        }).then(audio => this.setState({audio}));

    }

    stopMicrophone() {
        this.state.audio.getTracks().forEach(track => track.stop());
        this.setState({audio: null});
    }

    toggleMicrophone() {
        if (this.state.audio) {
            this.stopMicrophone();
            if (this.props.socket.readyState === 1) {
                this.props.socket.send('end');
                this.setState({sending: false})
            }
            this.setState({alreadyPlayed: false})
            this.props.microphoneStopped();
        } else {
            if (this.props.socket.readyState === 1) {
                this.props.socket.send('start');
                this.setState({sending: true});
            } else {
                this.props.socket.onconnect = () => {
                    this.setState({sending: true});
                    this.props.socket.send('start');
                }
            }
            this.getMicrophone();
            this.props.microphoneStarted();
        }
    }

    buttonStyle = () => {
        const {audio, sending, alreadyPlayed} = this.state;

        if (audio && sending && !alreadyPlayed) {
            return {
                variant: 'warning',
                text: 'Stop & Save',
                disabled: false,
                showRecord: true,
                guideText: 'Recording...'
            }
        } else if (!alreadyPlayed && (!audio || !sending)) {
            return {
                variant: 'success',
                text: 'Start Recording',
                disabled: false,
                showRecord: audio && sending,
                guideText: 'Click the button, wait until the button turns yellow, then you can start speaking.'
            }
        } else if (alreadyPlayed) {
            return {
                variant: 'secondary',
                text: 'Thank you for using DataVID!',
                disabled: true,
                showRecord: false,
                guideText: 'Form filled!'
            }
        }
    };


    render() {
        const {text, variant, disabled, showRecord, guideText} = this.buttonStyle();
        return (
            <div className="App">
                <div className="text-center">
                    <Button
                        onClick={this.toggleMicrophone}
                        variant={variant}
                        disabled={disabled}>
                        <span>{text} </span>
                        <span className="fas fa-microphone fa-1x"></span>
                    </Button>
                </div>
                {showRecord ? <Record socket={this.props.socket} audio={this.state.audio}/> : ''}
            </div>
        );
    }
}

export default MicToggle;