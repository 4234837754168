import React from "react";
import "./../styles/global.scss";
import NavbarCustom from "./../components/NavbarCustom";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import PricingPage from "./pricing";
import AddDataPage from "./adddata";
import ViewDataPage from "./viewdata";
import DashboardPage from "./dashboard";
import ContactPage from "./contact";
import DataSentPage from "./datasent";
import {Switch, Route, Router} from "./../util/router.js";
import Footer from "./../components/Footer";
import {ProvideAuth} from "./../util/auth.js";

function App(props) {
    return (
        <ProvideAuth>
            <Router>
                <>
                    <img
                        className="mx-auto d-block mt-2 mobile-30"
                        src="/logo.png"
                        alt="Logo"
                        height="60"
                    ></img>

                    <Switch>
                        <Route exact path="/" component={AddDataPage}/>

                        <Route exact path="/add-data" component={AddDataPage}/>

                        <Route exact path="/data-sent" component={DataSentPage}/>
                        <Route
                            component={({location}) => {
                                return (
                                    <div
                                        style={{
                                            padding: "50px",
                                            width: "100%",
                                            textAlign: "center"
                                        }}
                                    >
                                        The page <code>{location.pathname}</code> could not be
                                        found.
                                    </div>
                                );
                            }}
                        />
                    </Switch>
                </>
            </Router>
        </ProvideAuth>
    );
}

export default App;
