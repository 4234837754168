import React, {Component} from "react";
import socketIOClient from "socket.io-client";
import PatientData from "./PatientData";
import {registerOnMessageCallback, startWebsocketConnection} from "../util/websocket";
import {v4 as uuidv4} from "uuid";

class ConnectedPatientData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            response: {},
            endpoint: "http://localhost:8080"
        };
    }

    processData = data => {
        const state = {...this.state.response};

        data.symptoms = data.symptoms.join(', ');
        data.comorbidities = data.comorbidities.join(', ');
        data.disease_confirmation_type = data.disease_confirmation_type.join(', ');
        data.epidemiological_anamnesis = data.epidemiological_anamnesis.join(', ');
        data.therapies = data.therapies.join(', ');
        data.complications = data.complications.join(', ');

        Object.keys(data).forEach(key => {
            state[key] = data[key] ? data[key] : state[key]
        });

        state.isRecognized = data.isRecognized;

        this.setState({response: state})
    };

    onMessageReceived(msg) {
        const data = JSON.parse(msg)
        console.log("Message received from NLP service!")
        this.processData(data)
    }

    componentDidMount() {
        const id = uuidv4()
        localStorage.setItem('id', id)
        const nlpUrl = process.env.REACT_APP_NLP_URL + `/ws?id=${id}`
        const socket = startWebsocketConnection(nlpUrl)
        this.setState({socket: socket});
        socket.onmessage = (e) => {
            this.onMessageReceived(e.data)
        }
        console.log("Websocket connected to NLP service!")
    }

    render() {
        const {response} = this.state;
        console.log(response);
        return (
            <>
                <PatientData
                    router={this.props.router}
                    data={response}
                    showNameField={this.props.showNameField}
                    buttonText={this.props.buttonText}
                    buttonColor={this.props.buttonColor}
                    inputSize={this.props.inputSize}
                />
                {response.isRecognized === false &&
                    <div className="alert alert-danger" role="alert">
                        The intent was not recognized. Please try using different words.
                    </div>
                }
            </>
        );
    }
}

export default ConnectedPatientData;