import React from "react";
import Container from "react-bootstrap/Container";
import Section from "../components/Section";
import SectionHeader from "../components/SectionHeader";
import Button from "react-bootstrap/Button";
import {useRouter} from "../util/router";

function DataSentPage(props) {
  const router = useRouter();
  return (
    <Section
      bg="white"
      textColor="dark"
      size="md"
      bgImage=""
      bgImageOpacity={1}
    >
      <div className="d-flex align-items-center justify-content-center min" style={{height: "50vh"}}>
        <div className="text-center d-block">
        <SectionHeader
          title="Your data has been saved."
          subtitle=""
          size={3}
          spaced={true}
          className="text-center"
        ></SectionHeader>
          <Button size="lg" onClick={() => {
            router.push("/");
          }}>
            Add new patient data
          </Button>
        </div>
      </div>
    </Section>
  );
}

export default DataSentPage;

