import React, {Component} from 'react';
import {Field, Form as FinalForm} from 'react-final-form';
import Button from "react-bootstrap/Button";
import "./PatientData.scss";
import {useRouter} from "../util/router";


export default class EmployeeEdit extends Component {

    renderCheck = (data, field) => {
        if (data && data[field]) {
            return (<span
                className={
                    "Check fas fa-check"}
            />)
        } else {
            return (<span/>)
        }
    };

    render() {
        const {data} = this.props;
        return (
            <div id="employee-edit">
                <FinalForm
                    onSubmit={(e) => console.log(e)}
                    initialValues={data}
                    render={({handleSubmit, form, submitting, pristine}) => (
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="form-group col-6 col-md-6">
                                    <label>Age</label>
                                    <Field name="age" type="number" component="input" placeholder="26"
                                           className="form-control"/>
                                    {this.renderCheck(data, "age")}
                                </div>

                                <div className="form-group col-6 col-md-6">
                                    <label>Sex</label>
                                    <Field name="sex" component="input" placeholder="Sex" className="form-control"/>
                                    {this.renderCheck(data, "sex")}
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-12 col-md-6">
                                    <label>Symptoms</label>
                                    <Field name="symptoms" component="input"
                                           placeholder="Fever, headache, cough, fatigue,..." className="form-control"/>
                                    {this.renderCheck(data, "symptoms")}
                                </div>

                                <div className="form-group col-12 col-md-6">
                                    <label>Comorbidities</label>
                                    <Field name="comorbidities" component="input"
                                           placeholder="Hypertension, diabetes, obesity,..." className="form-control"/>
                                    {this.renderCheck(data, "comorbidities")}
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-12 col-md-6">
                                    <label>Disease confirmation type</label>
                                    <Field name="disease_confirmation_type" component="input"
                                           placeholder="PCR, serology, suspect" className="form-control"/>
                                    {this.renderCheck(data, "disease_confirmation_type")}
                                </div>

                                <div className="form-group col-12 col-md-6">
                                    <label>Epidemiological anamnesis</label>
                                    <Field name="epidemiological_anamnesis" component="input"
                                           placeholder="Negative, contact with sick person,..."
                                           className="form-control"/>
                                    {this.renderCheck(data, "epidemiological_anamnesis")}
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col-12 col-md-6">
                                    <label>Therapies</label>
                                    <Field name="therapies" component="input"
                                           placeholder="Oxygen, ventilation, antibiotic,..." className="form-control"/>
                                    {this.renderCheck(data, "therapies")}
                                </div>

                                <div className="form-group col-12 col-md-6">
                                    <label>Complications</label>
                                    <Field name="complications" component="input"
                                           placeholder="Pneumonia, sepsis, shock,..."
                                           className="form-control"/>
                                    {this.renderCheck(data, "complications")}
                                </div>
                            </div>
                        </form>
                    )}
                />
            </div>
        );
    }
}