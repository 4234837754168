import React from "react";
import AddPatientDataSection from "./../components/AddPatientDataSection";
import {registerOnMessageCallback, send, startWebsocketConnection} from "../util/websocket";
import {v4 as uuidv4} from "uuid";
import Container from "react-bootstrap/Container";
import MicToggle from "./MicToggle";

class AddDataPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: "",
            recording: false
        };
        this.id = uuidv4()
    }

    onMessageReceived(msg) {
        const data = JSON.parse(msg)
        console.log("Message received!")
        if (data.text) {
            this.setState({
                messages: data.text
            })
        }
    }

    componentDidMount() {
        const id = localStorage.getItem("id")
        const speechToTextUrl = process.env.REACT_APP_SPEECH_TO_TEXT_URL + `?uuid=${id}`
        const socket = startWebsocketConnection(speechToTextUrl)
        this.setState({socket: socket});
        registerOnMessageCallback(this.onMessageReceived.bind(this))
        console.log("Websocket connected to Speech2Text service!")
    }

    microphoneStopped = () => {
        this.setState({recording: false})
    }

    microphoneStarted = () => {
        this.setState({recording: true})
    }

    voiceRecognition = () => {
        const {socket} = this.state;
        return (<MicToggle
            socket={socket}
            microphoneStopped={this.microphoneStopped}
            microphoneStarted={this.microphoneStarted}
        />)
    };


    render() {
        return (
            <Container>
                <div>
                    <AddPatientDataSection
                        id={this.id}
                        bg="white"
                        textColor="dark"
                        size="md"
                        bgImage=""
                        bgImageOpacity={1}
                        title="Fill In Patient Data"
                        subtitle=""
                        buttonText="Send data"
                        buttonColor="primary"
                        showNameField={true}
                        inputSize="md"
                        content={this.voiceRecognition}>
                    </AddPatientDataSection>
                </div>
                <div className='text-center mt-3'>
                    {this.state.recording ? <img src="/audio-wave.gif" height="30"/> : ''}
                    <blockquote>{this.state.messages}</blockquote>
                </div>
            </Container>
        );
    }

}

export default AddDataPage;