import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useAuth } from "./../util/auth.js";
import {Link} from "../util/router";
import "./NavbarCustom.scss";

function NavbarCustom(props) {
  const auth = useAuth();

  return (
    <Navbar bg={props.bg} variant={props.variant} expand={props.expand} className="Navbar">
      <Container>
        <a href="https://mild.blue" target="_blank" rel="noopener noreferrer">
          <img
              className="d-inline-block align-top"
              src={props.logo}
              alt="Logo"
              height="30"
          ></img>
        </a>

        <div className="links right">
          <a href="https://datavid.org" target="_blank" rel="noreferrer">
            About
          </a>
          <a href="https://mild.blue/#contacts" target="_blank" rel="noreferrer">
            Contact
          </a>
        </div>
      </Container>
    </Navbar>
  );
}

export default NavbarCustom;
