import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import "./AddPatientDataSection.scss";
import ConnectedPatientData from "./ConnectedPatientData";
import {useRouter} from "../util/router";
import Button from "react-bootstrap/Button";


function AddPatientDataSection(props) {
    const router = useRouter();

    return (
        <Section
            bg={props.bg}
            textColor={props.textColor}
            size={props.size}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
        >
            <Container style={{
                maxWidth: "850px"
            }}
            >
                <ConnectedPatientData
                    id={props.id}
                    showNameField={props.showNameField}
                    buttonText={props.buttonText}
                    buttonColor={props.buttonColor}
                    inputSize={props.inputSize}
                    router={router}/>

                <div className="clearfix">
                    <div className="float-left">
                        {props.content && props.content()}
                    </div>

                    <Button className="float-right" onClick={() => {
                        router.push("/data-sent");
                    }}>
                        Confirm data
                    </Button>
                </div>

            </Container>
        </Section>
    );
}

export default AddPatientDataSection;
