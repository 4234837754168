import React from "react";


class Record extends React.Component {

    componentDidMount() {
        this.audioContext = new (window.AudioContext ||
            window.webkitAudioContext)();

        this.source = this.audioContext.createMediaStreamSource(this.props.audio);

        this.processor = this.audioContext.createScriptProcessor(4096, 1, 1);
        this.processor.onaudioprocess = evt => {
            const input = evt.inputBuffer.getChannelData(0) || new Float32Array(4096);

            const newData = new Int16Array(input.length);
            for (let idx = input.length; idx--;) {
                newData[idx] = 32767 * Math.min(1, input[idx]);
            }

            if (this.props.socket.readyState === 1) {
                this.props.socket.send(newData);
            }
        };

        this.source.connect(this.processor);
        this.processor.connect(this.audioContext.destination);
    }

    componentWillUnmount() {
        this.processor.disconnect();
        this.source.disconnect();
    }

    render() {
        return '';
    }
}

export default Record;